.display {
  width: 100vw;
  padding: 30px 20px 20px 20px;
  text-align: center;
}

.text {
  font-size: 50px;
  font-family: var(--headingFont);
  letter-spacing: 10px;
  text-transform: capitalize;
  color: var(--lightestColor);
  text-shadow: 0 0 3px black;
}

.subtext {
  font-size: 25px;
  font-family: var(--contentFont);
  letter-spacing: 2px;
  text-transform: capitalize;
  color: var(--contrastColor);
  text-shadow: 0 0 3px black;
}

@media only screen and (max-width: 768px) {
  .text {
    font-size: 30px;
  }
  .subtext {
    font-size: 15px;
  }
}
