.display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--footerColor);
  font-size: var(--normalFont);
  font-family: var(--contentFont);
  padding: 0px 5px;
  text-align: center;
  color: var(--lightestColor);
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  max-width: fit-content;
  padding: 5px;
  /* background-color: bisque; */
}

.iconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  size: 1rem;
  margin: 15px 20px;
  /* background-color: blueviolet; */
}

.iconBox:hover {
  color: var(--darkColor);
  cursor: pointer;
  transform: scale(1.3);
  transition-delay: 0s;
  transition: all 0.2s ease-in-out;
}

.credits {
  display: flex;
  justify-items: flex-start;
  padding-bottom: 20px;
  font-size: 16px;
  letter-spacing: 1px;
  /* background-color: aqua; */
}

span {
  color: var(--darkColor);
  cursor: pointer;
}

span:hover {
  text-decoration: none;
  color: var(--contrastColor);
}

.copyrights {
  display: flex;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: bolder;
}

.back_nav img {
  height: 60px;
  width: 75px;
}

.back_nav {
  position: absolute;
  top: 30px;
  left: 30px;
}

.back_nav > img {
  transition: 0.3s all;
}

.back_nav > img:hover {
  cursor: pointer;
  transform: rotate(90deg);
}


/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {

}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .back_nav{
    display: none;
  }
}