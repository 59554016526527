.display {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: var(--mb-3);
  margin-bottom: 30px;
  background-image: linear-gradient(
    315deg,
    var(--lightColor) 0%,
    var(--darkColor) 74%
  );
}

.content {
  height: 190px;
  box-shadow: 0 0 5px #000000;
  width: 195px;
  margin: var(--mb-4);
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  border: 1px solid transparent;
  text-align: center;
  /*=== Text ===*/
  color: var(--lightColor);
  font-size: 25px;
  font-family: var(--contentFont);
  background-image: linear-gradient(
    315deg,
    var(--darkColor) 0%,
    var(--footerColor) 90%
  );
}

.content:hover {
  background-image: linear-gradient(
    315deg,
    var(--contrastColor) 0%,
    #834d01 90%
  );
  color: var(--footerColor);
  font-weight: bolder;
  cursor: pointer;
}
/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .contentContainer {
    margin-right: 30px;
  }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .body {
    flex-direction: column;
  }
}
