.display{
    width: fit-content;
    text-align: center;
    padding: 15px 10px;
}

.text{
    font-size: 30px;
    font-family: var(--infoFont);
    letter-spacing: 5px;
    text-transform: capitalize;
    color: var(--lightestColor);
}

.subtext{
    font-size: 20px;
    font-family: var(--contentFont);
    letter-spacing: 2px;
    text-transform: capitalize;
    color: var(--contrastColor);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
    .text{
        font-size: 25px;
    }
    .subtext{
        font-size: 15px;
    }
    .display{
        padding: 10px 10px;
    }
}
