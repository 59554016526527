.arrow {
  position: absolute;

  bottom: 2vh;
  left: 49.4%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.arrow span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid var(--lightColor);
  border-right: 5px solid var(--lightColor);
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}
.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

@media only screen and (max-width: 768px) {
  .arrow {
    left: 50.5%;
  }
}
