.display {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Landing page - Desktop and Tablet view */

.landingPC {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.landingPC .title {
  font-size: 80px;
  font-family: var(--headingFont);
  letter-spacing: 3px;
  color: black;
  position: absolute;
  top: 20px;
}

.date {
  font-family: var(--contentFont);
  font-size: 20px;
  color: var(--contrastColor);
  position: absolute;
  top: 120px;
}

.landingPC .logoImg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingPC .logoImg img {
  max-height: 70vh;
}

.landingPC .grid {
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 50px;
  padding-left: 70px;
}

.landingPC .gridContainer {
  display: grid;
  grid-template-rows: auto auto auto;
  padding: 10px;
}

.landingPC .gridItem {
  display: flex;
  align-items: center;
  font-size: 60px;
  font-family: var(--headingFont);
  text-shadow: 0 0 3px black;
  letter-spacing: 3px;
  min-width: 200px;
}

.landingPC .gridItem p {
  color: var(--lightestColor);
}

.landingPC .gridItem p:hover {
  color: var(--contrastColor);
  cursor: pointer;
}

.landingPC .gi_Right {
  justify-content: flex-start;
}

.landingPC .gi_Left {
  justify-content: flex-end;
}

.landingPC .gi_Right:nth-child(2) {
  position: relative;
  left: -50px;
}

.landingPC .gi_Right:nth-child(3) {
  position: relative;
  left: -100px;
}

.landingPC .gi_Left:nth-child(2) {
  position: relative;
  left: 50px;
}

.landingPC .gi_Left:nth-child(3) {
  position: relative;
  left: 100px;
}

.reg_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.landingMOBILE {
  display: none;
}

/* Media Queries */

@media only screen and (min-width: 1029px) and (max-width: 1200px) {
  .landingPC .gridItem {
    font-size: 50px;
  }
}

@media only screen and (min-width: 880px) and (max-width: 1029px) {
  .landingPC .gridItem {
    font-size: 40px;
  }
}

@media only screen and (max-width: 879px) {
  .landingPC {
    display: none;
  }

  .reg_container {
    width: 90%;
  }

  .landingMOBILE {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .logo_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    padding: 0px 20px;
  }

  .img_container {
    transform: rotate(-0.5deg);
  }

  .heading {
    font-size: 50px;
    font-family: var(--headingFont);
    letter-spacing: 3px;
    color: white;
    position: absolute;
    top: 20px;
  }

  .link_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    padding: 0px 20px;
    font-size: var(--mb-6);
    font-family: var(--headingFont);
    color: var(--lightColor);
  }

  .links p {
    color: var(--lightestColor);
  }
}
