.display{
    padding-top: 30px;
    width: 100%;
    margin-bottom: 50px;
}

.header{
    width: 100%;
    font-size: var(--mb-6);
    text-transform: uppercase;
    text-align: center;
    font-family: var(--headingFont);
}

.msgHeader{
    font-size: 25px;
    font-family: var(--contentFont);
    letter-spacing: 2px;
    text-align: center;
    text-transform: capitalize;
    color: var(--contrastColor);
    margin-bottom: 20px;
}

.subheader{
    letter-spacing: 2px;
    font-size: var(--mb-4);
    font-weight: bold;
    margin: var(--mb-2) 0;
    padding: var(--mb-2);
    color: white;
    font-family: var(--contentFont);
    margin-bottom: 25px;
    word-wrap: normal;
    text-align: center;
}

.form_field_wrapper{
    display:flex;
    flex: 1;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    font-family: var(--contentFont);
    /* min-height: 70vh; */
    background-image: linear-gradient(315deg, var(--lightColor) 0%, var(--darkColor) 74%);
    padding: var(--mb-6) var(--mb-2) var(--mb-2) var(--mb-2);
    /* border-radius: 20px; */
}

.form_field_lefthalf{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 00px 10px 0px 50px;
}

.form_field_righthalf{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 00px 50px 0px 10px;
}

.recaptcha_container {
    text-align: center;
}
    
.recaptcha {
    display: inline-block !important;
}

.form_field{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
 
input, select{
    background-color: var(--footerColor) !important;
    border: 1px solid transparent !important;
    border-radius: 5px;
    color: var(--white) !important;
    min-height: 35px;
    width: 80%;
    padding: 10px;
    margin-bottom: 30px !important;
    font-family: var(--contentFont);
    font-size: var(--mb-2);
    letter-spacing: 1.5px;
}
  
input:focus, select:focus{
    outline: 0px !important;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none !important;
    border: 2px solid var(--primary) !important;
}

.loader_container {
    margin: auto;
    min-height: 50vh;
    align-items: center;
    justify-content: center;
    display: flex;
}

.bt {
    background: var(--darkColor);
    font-family: var(--contentFont);
    font-size: var(--mb-2);
    padding: 6px 18px;
    border-radius: 4px;
    align-items: center;
    color: white;
    border-color: transparent;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: 0.2s all;
    letter-spacing: 1px;
  }
  
  .bt:hover {
    font-family: var(--contentFont);
    background: var(--contrastColor);
    color: black;
    font-weight: bold;
    transform: scale(1.1);
    letter-spacing: 2px;
    cursor: not-allowed;
}

    /* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
    .form_field_wrapper{
        display:flex;
        /* flex: 1; */
        width: 100%;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        font-family: var(--contentFont);
    }

    .form_field_lefthalf{
        padding: 30px 30px 0px 30px;
    }

    .form_field_righthalf{
        padding: 0px 30px 50px 30px;
    }

    .header{
        font-size: var(--mb-5);
        padding: 0px var(--mb-2);
    }
}