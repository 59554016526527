.bg{
    background: rgb(19,58,24);
    background: linear-gradient(121deg, rgba(19,58,24,1) 30%, rgba(77,119,78,1) 78%);
    min-height: 100vh;
}


/*===SCROLL BAR===*/

.SB::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.SB::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.SB::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #D62929;
}