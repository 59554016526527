.dialog_wrapper {
  min-width: 250px;
  padding: var(--mb-6);
  background-color: var(--lightestColor);
  position: relative;
  text-align: center;
}

.dialog_wrapper > p {
  font-family: var(--infoFont);
  font-size: 1.5rem;
  color: var(--text);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.icon {
  font-size: 3rem !important;
}

.close_icon {
  position: absolute;
  top: var(--mb-1);
  right: var(--mb-1);
  font-size: 2rem !important;
  transition: 0.2s all;
  cursor: pointer;
}

.close_icon:hover {
  transform: scale(1.2);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .dialog_wrapper > p {
    padding: var(--mb-6);
  }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .dialog_wrapper > p {
    padding: var(--mb-6);
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
